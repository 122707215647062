var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"BbHA-zHa3DIuXIZ92PpGF"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/profile";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

const disableAnalytics = process.env.NEXT_PUBLIC_DISABLE_ANALYTICS === 'true'

if (!disableAnalytics) {
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      'https://503ea0ffe0a9476cb4873b2fd6cba14b@o181698.ingest.sentry.io/5525310',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.05,
    environment: publicRuntimeConfig.environment,
    ignoreErrors: ['Object Not Found Matching Id'],
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  })
}
